/* You can add global styles to this file, and also import other style files */
@import url("https://unpkg.com/img-comparison-slider@7/dist/styles.css");
button:hover,
button:focus,
:focus {
    outline: none!important;
    text-decoration: none!important;
}
.mean-container{
    display: none;
}
.search-popup .modal-body {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
}
.search-popup .modal-dialog {
    max-width: 100%;
}
.close-search-modal{
    position: fixed;
    top: 20px;
}
@media only screen and (max-width: 767px) {
    .search-popup .modal-body {
         margin: auto;
    }
}
@media (max-width:991px){
    .main-menu-1{
        display: none;
    }
    .mean-container{
        display: block;
    }
    .meanmenu-reveal.meanclose .close-menu,
    .meanmenu-reveal.meanclose{
        right: 0px;
        left: auto;
        text-align: center;
        text-indent: 0px;
        font-size: 18px;
    }
    .bottom-head .mean-container .mean-bar{
        z-index: 99;
    }
}
.stars-rate li i{
    color: rgba(19, 27, 35, 0.2);
}
.bg-black .stars-rate li i{
    color: #fff;
}
.stars-rate li i.active,
.bg-black .stars-rate li i.active{
    color: #f6ae2d;
}
.each-price h4{
    width: max-content;
}
.each-price-2.active,
.each-price.active{
    background-image: url(assets/img/bg/cleanitems.jpg);
    z-index: 1;
}
.each-price-2.active .price-2-heads{
    position: relative;
}
.each-price-2[data-overlay]::before,
.each-price[data-overlay]::before{
    z-index: -1;
}
.each-price.active .main-price,
.each-price.active .main-price sub,
.each-price.active .main-price sup,
.each-price.active .price-features li,
.each-price.active h4{
    color: #fff;
}
.each-price-2.active img,
.service2-img .icon.bg-blue img{
    filter: brightness(100);
}
.owl-nav{
    display: flex;
}
.owl-nav div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.twentytwenty-container .before,
.twentytwenty-container  .after {
    margin: 0;
}
.twentytwenty-container  .before figcaption,
.twentytwenty-container  .after figcaption {
    background: rgb(0 0 0 / 20%);
    border-radius: 2px;
    color: white;
    opacity: 1;
    line-height: 38px;
    padding: 0 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.twentytwenty-container .before figcaption {
    left: 10px;
}
.twentytwenty-container .after figcaption {
    right: 10px;
}
.slider-3.vector-slide{
    background-size: cover;
}
.login-section .card-header .btn,
.faq-boxes .card-header .btn{
    width: 100%;
    padding: 0;
    text-align: left;
    border: none;
}
.faq-boxes .card-header h5 i{
    transform: rotate( 45deg );
    transition: all .4s;
}
.faq-boxes .card-header .btn.collapsed h5 i{
    transform: rotate(0deg );
    transition: all .4s;
}
.testimonial-3-slide .owl-carousel .owl-nav {
    justify-content: center;
}
.popular-post-img{
    height: 82px;
}
.popular-post-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
section.faq .row>div>div:last-child .hr-1{
    display: none;
}
.custom-quantity.quantity {
    border: 1px solid #e6e6e6;
    max-width: 142px;
    border-radius: 3px;
}
.custom-quantity.quantity .add {
    width: 40px;
    height: 40px;
    text-align: center;
    color: #666;
    line-height: 40px;
    float: left;
    font-size: 14px;
    background: transparent;
    border: none;
    padding: 0;
}
.custom-quantity.quantity .plus-btn {
    float: right;
    position: relative;
    top: 0;
    right: 0;
}
.custom-quantity.quantity input {
    padding: 9px 10px;
    border: none;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #14287b;
    margin-left: 4px;
}
.prdt-tabs .table tr:first-child{
    display: none;
}
/* Pagination */
pagination-template .ngx-pagination {
	margin: 0px 0 0rem;
	justify-content: center;
	display: flex;
	padding-left: 0;
	list-style: none;
    flex-wrap: wrap;
}
pagination-template .ngx-pagination li {
	margin: 0;
	position: relative;
    margin-bottom: 15px!important;
}
pagination-template .ngx-pagination li.pagination-next, pagination-template .ngx-pagination li.pagination-previous {
	padding: 0;
	display: block;
}
pagination-template .ngx-pagination li.pagination-next span, pagination-template .ngx-pagination li.pagination-previous span, pagination-template .ngx-pagination li a {
	color: #212121;
	border: 2px solid #f9fafc;
	background-color: #f9fafc;
	font-size: 14px;
	font-weight: 400;
    padding: 9px 15px !important;
	position: relative;
	margin-left: 15px;
	display: block;
	line-height: 1.5;
    border-radius: 50%;
}
pagination-template .ngx-pagination li.current {
	color: #fff;
	border: 2px solid #0C2278;
	background-color: #0C2278; 
	font-size: 14px;
	font-weight: 400;
    padding: 9px 15px !important;
	position: relative;
	margin-left: 15px;
	display: block;
	line-height: 1.5;
    border-radius: 50%;
    box-shadow: 0 5px 6px rgb(0 0 0 / 18%);
}
pagination-template .ngx-pagination li.pagination-next::after, pagination-template .ngx-pagination li.pagination-next a:after, pagination-template .ngx-pagination li.pagination-previous a::before, pagination-template .ngx-pagination li.pagination-previous::before {
	display: none!important;
}
pagination-template .ngx-pagination li.pagination-previous a,
pagination-template .ngx-pagination li.pagination-previous span{
    margin: 0;
}
@media (max-width: 600px){
    pagination-template .ngx-pagination{
        justify-content: center;
        margin-top: 20px;
    }
    .ngx-pagination.responsive .small-screen{
        display: none!important;
    }
    .ngx-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
        display: block!important;
    }
}
.countdownclock .countdown{
    flex-wrap: wrap!important;
    justify-content: space-between!important;
}
.countdownclock .measurements{
    margin-right: 55px;
}
.countdownclock .measurements .measurements-number{
    font-weight: bold;
    position: relative;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
}
.countdownclock .measurements .measurements-text{
    font-size: 15px;
    font-weight: 500;
    font-style: italic;
    position: static;
    vertical-align: top;
    top: -.5em;
    line-height: 0;
    margin-bottom: 0;
    font-family: 'Montserrat', sans-serif;
}
.countdownclock .measurements.Weeks{
    display: block;
    flex: auto!important;
    width: 100%;
    position: relative;
}
.countdownclock .measurements.Weeks .measurements-number{
    color: #fff;
    font-size: 100px;
    font-weight: bold;
    position: relative;
    display: inline-block;
    line-height: 1;
    margin-bottom: 0px;
    font-family: inherit;
}
.countdownclock .measurements.Weeks .measurements-text{
    position: absolute;
    left: 120px;
    top: 9px;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-style: italic;
    line-height: 0;
    vertical-align: baseline;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
